<template>
  <div class="d-flex align-center justify-center w-100 h-100 pt-5 pr-5 pb-5">
    <div
      class="d-flex flex-column align-center justify-center w-100 h-100 rounded-lg bg-white"
    >
      <div>
        <v-img
          :src="require('@/assets/svg/empty-post.svg')"
          style="width: 60px; height: 50px"
          contain
        />
      </div>
      <div class="mt-2">No messages</div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
